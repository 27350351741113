* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-text-size-adjust: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; 
}

@font-face {
  font-family: Toyota-bd;
  src: url(../public/fonts/ToyotaDisplay_Bd.ttf);
}

@font-face {
  font-family: Toyota-he;
  src: url(../public/fonts/ToyotaDisplay_He.ttf);
}

@font-face {
  font-family: Toyota-rg;
  src: url(../public/fonts/ToyotaDisplay_Rg.ttf);
}

.page-main {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ec232f;
  min-height: 100dvh;
}

.page-main .main-wrapper {
  max-width: 500px;
  width: 100%;
  border: solid 1px #ffffff70;
  background-image: url(../public/images/background.png);
  background-size: 600px;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-attachment: fixed;
}

.page-main .main-wrapper .countries-list-section {
  overflow-y: auto;
  overflow-x: hidden;
  padding-inline: 10px;
  direction: rtl;
  margin: 5px;
}

.page-main .main-wrapper .countries-list-section::-webkit-scrollbar {
  width: 8px;
}

.page-main .main-wrapper .countries-list-section::-webkit-scrollbar-track {
  background: #ffffff7a;
  border-radius: 20px;
}

.page-main .main-wrapper .countries-list-section::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 20px;
}

.page-main .main-wrapper .banner {
  width: 100%;
  display: flex;
  z-index: 1111;
}

.page-main .main-wrapper .banner img {
  width: 100%;
}

.page-main .country-list-wrapper {
  display: flex;
  flex-direction: column;
  margin-block: 16px;
  direction: ltr;
  margin-bottom: 25px;
}

.page-main .country-list-wrapper:last-child {
  margin-bottom: 0;
}

.page-main .country-list-wrapper form.checkbox-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 8px;
  position: relative;
  z-index: 1111;
}

.page-main .country-list-wrapper form.checkbox-wrapper .item {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  color: #002a9300;
  font-size: 23px;
  border-radius: 3px;
  border: solid 1px #fff;
} 

.page-main .country-list-wrapper form.checkbox-wrapper .check-item input[disabled] + label {
  opacity: .5;
  cursor: not-allowed;
}

.page-main .country-list-wrapper form.checkbox-wrapper .check-item {
  position: relative;
}

button.submit-btn[disabled] {
  cursor: not-allowed;
  opacity: .5;
  background: #ffffff !important;
  color: #ec232f !important;
}

.page-main .country-list-wrapper form.checkbox-wrapper .check-item img {
  width: calc(100% - 30px);
  border-radius: 0 3px 3px 0;
  object-fit: contain;
  cursor: pointer;
}

.page-main .country-list-wrapper form.checkbox-wrapper .check-item input {
  display: none;
}

.page-main .country-list-wrapper form.checkbox-wrapper .check-item input + lebel {
  position: relative;
  cursor: pointer;
}

.page-main .country-list-wrapper form.checkbox-wrapper .check-item input + label:before {
  content: '';
  display: inline-block;
  position: relative;
  background: #ffffff;
  width: 25px;
  height: 29px;
  margin-right: 0px;
  cursor: pointer;
  border-radius: 3px 0 0 3px;
}

.page-main .country-list-wrapper form.checkbox-wrapper .check-item input + label {
  display: flex;
}

.page-main .country-list-wrapper form.checkbox-wrapper .check-item input + label:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 2px;
  background: #ec232f;
  border-radius: 50px;
  z-index: 11;
  width: 25px;
  height: 25px;
  transform: scale(0);
  transition-duration: .3s;
}

.page-main .country-list-wrapper form.checkbox-wrapper .check-item input:checked + label:after {
  transform: scale(.65);
}

.page-main .country-list-wrapper .title .font-h2 {
  text-align: center;
  color: #fff;
  font-size: 30px;
  line-height: 1;
  min-width: max-content;
  padding: 14px 0px 13px;
  font-family: Toyota-bd;
}

.page-main .country-list-wrapper .title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  position: relative;
  z-index: 1111;
}

.page-main .country-list-wrapper .title img { position: absolute; width: 100%; height: 100px; object-fit: cover; z-index: -1;}
.page-main .country-list-wrapper .title:before {
  content: '';
  display: inline-block;
  width: 50px;
  background-image: url(../public/images/ball.png);
  height: 50px;
  background-repeat: no-repeat;
}
.page-main .country-list-wrapper .title:after {
  content: '';
  display: inline-block;
  width: 50px;
  height: 50px;
  background-image: url(../public/images/ball.png);
  background-repeat: no-repeat;
}
.page-main .country-list-wrapper .title .font-h2 {
  margin: 0px 10px;
  width: max-content;
  display: block;
  text-transform: uppercase;
}

.PhoneInputCountrySelect {
  padding: 10px !important;
}

.page-main .PhoneInput {
  width: 100%;
  background-color: #ec232f;
  border-radius: 10px;
  padding-left: 15px;
}

form.form .form-wrapper .form-item .mobile-number input {
  background-color: transparent;
  outline: none;
}

.bottom-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px 0px;
}

.form .bottom-btns button {
  background-color: #ec232f !important;
  color: #fff !important;
  border: solid 1px #ec232f !important;
  transition-duration: .3s;
}

.form .bottom-btns button:hover {
  background-color: #fff !important;
  color: #ec232f !important;
  border: solid 1px #ec232f;
}

span.reset-btn {
  line-height: 1;
  cursor: pointer;
  font-size: 24px;
  font-family: 'Toyota-bd';
  color: #fdc200;
  margin-right: 20px;
  transition-duration: .3s;
}

span.reset-btn:hover {
  color: #fff;
}

button.submit-btn {
  padding: 15px 50px;
  font-size: 24px;
  font-family: 'Toyota-bd';
  background-color: #ffffff;
  color: #ed0013;
  border-radius: 40px;
  border: none;
  line-height: 1;
  text-transform: uppercase;
  cursor: pointer;
  transition-duration: .3s;
}

button.submit-btn:hover {
  background-color: #fcbb19;
  color: #000;
}

.unselect-position {
  position: relative;
  display: inline-block;
}

.unselect-position .items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}

.unselect-position .items .item {
  width: 100%;
  height: 35px;
  background: #ffffffe8;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 800;
  color: #002a93;
}

.form-page .main-wrapper {
  background-image: url(../public/images/background.png);
  background-size: cover;
  height: 100%;
  background-attachment: scroll;
}

.form {
  padding: 110px 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background-color: #ffffff !important;
}

.form .form-wrapper {
  width: 100%;
}

.form .form-wrapper .form-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  width: 100%;
}

.form > img {
  margin-bottom: 30px;
  width: 200px;
}

.form .form-wrapper .form-item input {
  padding: 8px 14px 10px;
  font-size: 18px;
  border-radius: 10px;
  border: none;
  box-sizing: border-box;
  font-family: 'Toyota-rg';
  outline: none;
  line-height: 1 !important;
  background: #ec232f;
  color: #ffffff;
}

.form .form-wrapper .form-item input::placeholder {
  color: #ffffff;
}

.form .form-wrapper .form-item p {
  font-size: 18px;
  color: #ec232f;
  font-family: 'Toyota-rg';
  margin-top: 3px;
}

.form .form-wrapper .form-item span {
  font-size: 16px;
  font-family: 'Toyota-rg';
  color: #000000;
}

.form .form-wrapper .form-item  .mobile-number {
  display: flex;
  width: 100%;
}

form.form .form-wrapper .form-item  .mobile-number input {
  width: 100%;
}

.form-page .main-wrapper .bottom-btns {
  margin-top: 50px;
}

.form-page .main-wrapper .goodluck-wrapper {
  padding: 75px 50px;
  text-align: center;
  height: 80dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.form-page .main-wrapper .goodluck-wrapper h1 {
  font-size: 95px;
  width: min-content;
  margin: 0 auto -50px;
  font-family: 'Toyota-he';
  line-height: .8;
  color: #FFFFFF;
  text-transform: uppercase;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media(max-width: 520px) {
  .page-main .main-wrapper { border: none; }
  .page-main .main-wrapper .countries-list-section { height: auto; background-image: contain; margin: 0px; }
  .page-main .country-list-wrapper form.checkbox-wrapper { grid-gap: 4px; }
  .page-main .country-list-wrapper form.checkbox-wrapper .check-item input + label::before { width: 20px; height: 20px; }
  .page-main .country-list-wrapper form.checkbox-wrapper .check-item input + label::after { width: 20px; height: 20px; top: 0; }
  .page-main .country-list-wrapper form.checkbox-wrapper .check-item img { width: calc(100% - 30px); }

  .page-main .main-wrapper .banner { position: relative; top: auto; }
  .page-main .country-list-wrapper form.checkbox-wrapper { grid-gap: 5px 1px; }
  .page-main .country-list-wrapper form.checkbox-wrapper .item { margin-inline: 0px 3px; font-size: 19px; }

  form.form { padding: 20px 50px; height: 100%; }
  .form-page .main-wrapper { background-image: none; }
  .form-page .main-wrapper .bottom-btns { margin-top: 0px; }
  .form-page { background-image: url(../public/images/background.png); background-size: cover; height: 100%; background-attachment: scroll; }
}
